import Layout from '@/components/Layout'
import '../styles/global.scss'
import 'react-dropdown/style.css'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { setLocalStorageItem } from '@/utils/localStorageHelper'
import sanityClient from '../services/sanityClient'
import { bebas, frank } from '@/utils/fonts'
const DynamicZendeskWidget = dynamic(
  () => import('@/components/CustomZendeskWidget/CustomZendeskWidget'),
  { ssr: false }
)

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { DefaultSeo } from 'next-seo'
import { defaultSEO } from '../config/seo.config'
  
// THE QUERY IS NOW FETCHED IN getInitialProps BELOW
const QUERY = `
[
  *[handle.current == "header-settings"] {
    _type,
    _rev,
    "objectID": _id,
    _createdAt,
    _updatedAt,
    title,
    header,
    handle,
    logo{
      asset->{
        published,
        type,
        sha1hash,
        metadata,
        assetId,
        originalFilename,
        extension,
        uploadId,
        path,
        mimeType,
        url,
      },
    },
    memberPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    nonMemberPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    nonMemberPrimaryNavigationBTestVariant->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    pdpPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    memberPortalPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    primaryAnnouncement,
    memberCta,
    nonMemberCta,
    Search,
    customerService{
      header,
      subheader,
      customerServiceNavigation->{
        title,
        handle,
        menuItems[]{
          _key,
          linkText,
          linkUrl
        }
      }
    },
  },
  *[handle.current == "footer-settings"] {
    _type,
    _rev,
    "objectID": _id,
    _createdAt,
    _updatedAt,
    title,
    header,
    handle,
    logo{
      asset->{
        published,
        type,
        sha1hash,
        metadata,
        assetId,
        originalFilename,
        extension,
        uploadId,
        path,
        mimeType,
        url,
      },
    },
    footerProps,
    copyright,
    footerNavigationList[]{
      _key,
      title,
      navigation->{
        title,
        handle,
        menuItems[]{
          _key,
          linkText,
          linkUrl
        }
      }
    },
    socialLinks[]{
      _key,
      platform,
      socialLink
    },
    termsLinks[]{
      _key,
      linkText,
      linkUrl
    },
    copyright,
    emailSignup
  }
]
`

const AppContainer = ({ Component, pageProps, settings }) => {
  const router = useRouter()
  const headerSettings = settings?.[0]?.[0] || null
  const footerSettings = settings?.[1]?.[0] || null
  const searchLinks = settings?.[2]?.[0] || null

  useEffect(() => {
    const addonParam = router.query.addon
    if (addonParam) {
      setLocalStorageItem('allowAddon', `${addonParam}`)
    }
  }, [router.query])

  // INITIALIZE MARKETING TAGS
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== 'development' && typeof window !== 'undefined') {
      import('react-gtm-module').then(({ default: TagManager }) => {
        TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
      })
    }
  }, [router.isReady])

  return (
    <main className={`${bebas.variable} ${frank.variable}`}>
      <DefaultSeo {...defaultSEO} />
      <Layout
        headerSettings={headerSettings}
        footerSettings={footerSettings}
        searchLinks={searchLinks}
      >
        <Component {...pageProps} />
      </Layout>

      <Script
        id="elevar"
        strategy="beforeInteractive"
        onReady={() => {
          console.debug('Elevar script LOADED')
        }}
        onError={() => {
          console.error('Elevar script FAILED TO LOAD')
        }}
      >
        {`
        async function loadElevar() {
          try {
            const settings = {};
            const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/acaae91fdd050d2cbe90e9794654c3779b11e2db/config.js")).default;
            const scriptUrl = settings.proxyPath
              ? settings.proxyPath + config.script_src_custom_pages_proxied
              : config.script_src_custom_pages;
            if (scriptUrl) {
              const { handler } = await import(scriptUrl);
              await handler(config, settings);
            }
          } catch (error) {
            console.error("Elevar Error:", error);
          }
        }
        loadElevar();
        `}
      </Script>

      {/* Add Clarity script */}
      <Script
        id="ms_clarity"
        strategy="afterInteractive"
        onReady={() => {
          console.debug('Clarity script LOADED')
        }}
        onError={() => {
          console.error('Clarity script FAILED TO LOAD')
        }}
      >
        {`
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "qddshl0kie");
        `}
      </Script>
      <DynamicZendeskWidget />
    </main>
  )
}

// FETCH DATA ON SERVER SIDE
AppContainer.getInitialProps = async () => {
  const settings = await sanityClient.fetch(QUERY)
  return { settings }
}

export default AppContainer
