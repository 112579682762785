import { createContext, useContext, useReducer, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic';

const DynamicEditScheduleDrawer = dynamic(() => import('@/components/Account/EditScheduleDrawer/EditScheduleDrawer'));
const DynamicEditScheduleOverlay = dynamic(() => import('@/components/Account/EditScheduleOverlay/EditScheduleOverlay'));
export const EditScheduleDrawerContext = createContext()

function drawerReducer(state, action) {
  switch (action.type) {
    case 'update_subscription': {
      return {
        ...state,
        ...action.payload,
      }
    }
    case 'open_drawer': {
      return {
        ...state,
        isOpen: true,
        ...action.payload
      };
    }
    case 'close_drawer': {
      return {
        ...state,
        isOpen: false,
        activeProductHandle: null
      };
    }
    case 'add_box_data': {
      return {
        ...state,
        boxManager: action.payload
      };
    }
    default:
      return state;
  }
}

const initialState = {
  isOpen: false,
  subscription: null,
  harvestMonth: null,
  isModal: false,
};

export function useEditScheduleDrawerContext() {
  return useContext(EditScheduleDrawerContext)
}

export function EditScheduleDrawerProvider({ children }) {
  const router = useRouter()
  const [state, dispatch] = useReducer(drawerReducer, initialState)
  const { isOpen, subscription, harvestMonth, isModal } = state

  const openDrawer = ({ subscription, harvestMonth, isModal = false }) => {
    dispatch({ type: 'open_drawer', payload: { subscription, harvestMonth, isModal } })
  }

  const updateSubscription = useCallback(({ subscription, harvestMonth }) => {
    dispatch({ type: 'update_subscription', payload: { subscription, harvestMonth } })
  }, []);

  useEffect(() => {
    if (isOpen) document.querySelector('html').classList.add('disable-scroll')
    if (!isOpen) document.querySelector('html').classList.remove('disable-scroll')
  }, [isOpen])

  useEffect(() => {
    dispatch({ type: 'close_drawer' })
  }, [router])

  return (
    <EditScheduleDrawerContext.Provider 
      value={{ isOpen, currentOpenSubscription: subscription, openDrawer, updateSubscription, dispatch, isModal }}>
        {isOpen && !isModal &&
          <DynamicEditScheduleDrawer subscription={subscription} harvestMonth={harvestMonth} />
        }
        {isOpen && isModal &&
          <DynamicEditScheduleOverlay subscription={subscription} harvestMonth={harvestMonth} />
        }
      {children}
    </EditScheduleDrawerContext.Provider>
  )
}
