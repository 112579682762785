/**
 * EasyPost service for address validation
 * This service handles communication with EasyPost API via our server-side API route
 * to keep API keys secure and handle CORS restrictions
 */

/**
 * Validates an address using EasyPost API
 * @param {Object} address - The address to validate
 * @param {string} address.street1 - Street address line 1
 * @param {string} address.street2 - Street address line 2 (optional)
 * @param {string} address.city - City
 * @param {string} address.state - State/Province
 * @param {string} address.zip - ZIP/Postal code
 * @param {string} address.country - Country code
 * @returns {Promise<Object>} Validation result object
 * @returns {boolean} result.isValid - Whether the address is valid
 * @returns {Object|null} result.normalizedAddress - Normalized address if valid
 * @returns {Array} result.errors - Array of validation errors if not valid
 * @returns {string|undefined} result.error - General error message if request failed
 */
export async function validateAddress(address) {
  try {
    // Call the API route that acts as a proxy to EasyPost
    const response = await fetch('/api/validate-address', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ address })
    });

    if (!response.ok) {
      throw new Error(`Error validating address: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error in validateAddress service:', error);
    return { 
      isValid: false, 
      error: error.message || 'Failed to validate address' 
    };
  }
} 