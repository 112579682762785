import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useHeadlessCheckoutContext } from '@/context/HeadlessCheckoutContext';

const Notification = ({
  severity = 'error',
  autoHideDuration = 6000,
}) => {
  const { 
    displayNotificationError, 
    setDisplayNotificationError,
    notificationMessage = "Please provide a valid shipping address to continue."
  } = useHeadlessCheckoutContext()

  const handleClose = (_event, reason) => {
    setDisplayNotificationError(false);

    if (reason === 'clickaway') return;
  };

  return (
    <Snackbar 
      open={displayNotificationError} 
      autoHideDuration={autoHideDuration} 
      onClose={handleClose}
      sx={{ zIndex: 10000 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={handleClose} 
        severity={severity} 
        sx={{ 
          width: '100%',
          fontFamily: '"Montserrat", sans-serif',
          '& .MuiAlert-message': {
            fontFamily: 'inherit',
          }
        }} 
        variant="filled"
      >
        {notificationMessage}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
