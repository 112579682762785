import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useMemberAccountContext } from './MemberAccountContext'

const DynamicAddonDrawer = dynamic(
  () => import('@/components/Account/ProductDrawer/AddonDrawer'),
)
const DynamicProductSwapDrawer = dynamic(
  () => import('@/components/Account/ProductDrawer/ProductSwapDrawer'),
)

const SubscriptionProductDrawerContext = createContext()

function drawerReducer(state, action) {
  switch (action.type) {
    case 'update_subscription': {
      return {
        ...state,
        subscription: action.payload,
      }
    }
    case 'open_drawer': {
      return {
        ...state,
        isOpen: true,
        subscription: action.payload.subscription,
        swapProduct: action.payload.swapProduct,
        drawerType: action.payload.type,
        unswappedSubscriptionProducts:
          action.payload.unswappedSubscriptionProducts,
        presetSwapReason: action.payload.presetSwapReason,
        isOverlay: action.payload.isOverlay,
      }
    }
    case 'close_drawer': {
      return {
        ...state,
        isOpen: false,
        activeProductHandle: null,
      }
    }
    case 'add_box_data': {
      return {
        ...state,
        boxManager: action.payload,
      }
    }
    default:
      return state
  }
}

const initialState = {
  isOpen: false,
  subscription: null,
  drawerType: null,
  swapProduct: null,
  unswappedSubscriptionProducts: [],
  presetSwapReason: null,
  isOverlay: false,
}

export function useSubscriptionProductDrawerContext() {
  return useContext(SubscriptionProductDrawerContext)
}

export function SubscriptionProductDrawerProvider({ children }) {
  let drawer
  const router = useRouter()
  const [state, dispatch] = useReducer(drawerReducer, initialState)
  const {
    isOpen,
    subscription,
    swapProduct,
    unswappedSubscriptionProducts,
    presetSwapReason,
    isOverlay,
  } = state
  const { availableAddons, availableSwapProducts } = useMemberAccountContext()

  const openAddonDrawer = async (subscription) => {
    dispatch({ type: 'open_drawer', payload: { subscription, type: 'addon' } })
  }

  const openProductSwapDrawer = async ({
    subscription,
    swapProduct,
    unswappedSubscriptionProducts,
    presetSwapReason,
    isOverlay,
  }) => {
    if (!subscription)
      console.warn(
        'swap drawer opened without subscription for subscription based pricing',
      )
    dispatch({
      type: 'open_drawer',
      payload: {
        subscription,
        swapProduct,
        unswappedSubscriptionProducts,
        type: 'swap',
        presetSwapReason,
        isOverlay,
      },
    })
  }

  const updateSubscription = useCallback((subscription) => {
    dispatch({ type: 'update_subscription', payload: subscription })
  }, [])

  useEffect(() => {
    if (isOpen) document.querySelector('html').classList.add('disable-scroll')
    if (!isOpen)
      document.querySelector('html').classList.remove('disable-scroll')
  }, [isOpen])

  useEffect(() => {
    dispatch({ type: 'close_drawer' })
  }, [router])

  switch (state.drawerType) {
    case 'addon':
      drawer = (
        <DynamicAddonDrawer
          subscription={subscription}
          addons={availableAddons}
        />
      )
      break
    case 'swap':
      drawer = (
        <DynamicProductSwapDrawer
          subscription={subscription}
          swapProduct={swapProduct}
          unswappedSubscriptionProducts={unswappedSubscriptionProducts}
          swapables={availableSwapProducts}
          presetSwapReason={presetSwapReason}
          isOverlay={isOverlay}
        />
      )
      break
    default:
      drawer = undefined
      break
  }

  return (
    <SubscriptionProductDrawerContext.Provider
      value={{
        isOpen,
        currentOpenSubscription: subscription,
        openAddonDrawer,
        openProductSwapDrawer,
        updateSubscription,
        dispatch,
      }}
    >
      {isOpen && drawer}
      {children}
    </SubscriptionProductDrawerContext.Provider>
  )
}
