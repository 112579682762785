import { forwardRef, useRef } from 'react'
import dynamic from 'next/dynamic'
import SecondaryNavigation from '../MainNavigation/SecondaryNavigation'
import { useRouter } from 'next/router'

const PrimaryAnnouncement = dynamic(() => import('../PrimaryAnnouncement'))
const MainNavigation = dynamic(() => import('../MainNavigation'))
const MobileMenu = dynamic(() => import('../MobileMenu'))
import { useHeaderContext } from '@/context/HeaderContext'
import SearchModal from '@/components/Layout/SearchModal'
import { useSearchModalContext } from '@/context/SearchModalContext'

import classes from './Header.module.scss'

const Header = forwardRef(({ content, pageHandle }, ref) => {
  const router = useRouter()
  const isMemberPortal = router.pathname.startsWith('/account/')

  const { hide } = useHeaderContext()
  const { searchOpen } = useSearchModalContext()
  const primaryAnnouncementRef = useRef()

  // console.log('pageHandle:', pageHandle)

  return (
    <>
      {searchOpen && <SearchModal ref={primaryAnnouncementRef} />}
      <div
        ref={ref}
        className={`${classes['header']} ${!hide ? classes['is-visible'] : ''}`}
      >
        {content.primaryAnnouncement?.showAnnouncement && (
          <PrimaryAnnouncement
            ref={primaryAnnouncementRef}
            props={content.primaryAnnouncement}
          />
        )}
        <MainNavigation props={content} pageHandle={pageHandle} />
      </div>
      {isMemberPortal && (
        <div className={classes.secondaryNavigationContainer}>
          <SecondaryNavigation props={content} pageHandle={pageHandle} />
        </div>
      )}
      {pageHandle !== 'purchaseFlow' && (
        <MobileMenu props={content} pageHandle={pageHandle} />
      )}
    </>
  )
})

export default Header
