import { getAvailableBabProducts } from '@/utils/getProducts'
import dynamic from 'next/dynamic'
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import sanityClient from '@/services/sanityClient'
import { getProductContentByHandle } from '@/utils/getProductContentByHandle'
import { getBoxDetailByHandles } from '@/utils/getBoxDetailByHandles'

const DynamicBabPurchaseDrawer = dynamic(() => import('@/components/Layout/BabDrawer/BabPurchaseDrawer'), {
  ssr: false,
})

const DynamicBabEditDrawer = dynamic(() => import('@/components/Layout/BabDrawer/BabEditDrawer'), {
  ssr: false,
})

const DynamicModifyContentsOverlay = dynamic(() => import('@/components/Layout/ModifyContentsOverlay/ModifyContentsOverlay'), {
  ssr: false,
})

const BabDrawerContext = createContext()

function drawerReducer(state, action) {
  switch (action.type) {
    case 'open_drawer': {
      return {
        isOpen: true,
        drawerType: action.payload.type,
        currentSubscriptionProducts: action.payload.currentProducts,
        subscription: action.payload.subscription,
        accountSettings: action.payload.accountSettings,
      }
    }
    case 'close_drawer': {
      return {
        ...state,
        isOpen: false,
      }
    }
    default:
      return state
  }
}

const initialState = {
  isOpen: false,
  drawerType: null,
  currentSubscriptionProducts: null,
  subscription: null,
  accountSettings: null,
}

export function useBabDrawerContext() {
  return useContext(BabDrawerContext)
}

export function BabDrawerContextProvider({ children }) {
  const [state, dispatch] = useReducer(drawerReducer, initialState)
  const { isOpen } = state
  const [availableProducts, setAvailableProducts] = useState([])
  const [productDetails, setProductDetails] = useState(undefined)

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await getAvailableBabProducts()

      const mappedProducts = await Promise.all(
        products?.map(async (product) => {
          const productContent = await getProductContentByHandle(
            product.handle,
            sanityClient,
          )
          return {
            ...product,
            productImages: productContent?.fields?.productImages,
            pricingDisplay: productContent?.fields?.pricingDisplay,
          }
        }),
      )

      setAvailableProducts(mappedProducts)
    }
    fetchProducts()
  }, [])

  async function loadBabProductDetails() {
    if (!productDetails) {
      const details = await getBoxDetailByHandles(['build-a-box'])
      setProductDetails(details[0])
    }
  }

  function openBabPurchaseDrawer() {
    loadBabProductDetails()
    dispatch({ type: 'open_drawer', payload: { type: 'purchaseFlow' } })
  }

  function openBabEditDrawer({ accountSettings, subscription }) {
    dispatch({
      type: 'open_drawer',
      payload: {
        type: 'subscriptionEdit',
        accountSettings,
        currentProducts: subscription.subscription_lineitems,
        subscription,
      },
    })
  }
  function openModifyContentsOverlay({ accountSettings, subscription }) {
    dispatch({
      type: 'open_drawer',
      payload: {
        type: 'modifyContents',
        accountSettings,
        currentProducts: subscription.subscription_lineitems,
        subscription,
      },
    })
  }

  function closeBabDrawer() {
    dispatch({ type: 'close_drawer' })
  }

  let drawer
  switch (state.drawerType) {
    case 'purchaseFlow':
      drawer = (
        <DynamicBabPurchaseDrawer
          availableProducts={availableProducts}
          babProductDetails={productDetails}
        />
      )
      break
    case 'subscriptionEdit':
      drawer = (
        <DynamicBabEditDrawer
          subscription={state.subscription}
          availableProducts={availableProducts}
          currentSubscriptionProducts={state.currentSubscriptionProducts}
          title={state.accountSettings?.subscriptionEditingFlyout.title}
          description={
            state.accountSettings?.subscriptionEditingFlyout.description
          }
          buttonText={
            state.accountSettings?.subscriptionEditingFlyout.buttonText
          }
        />
      )
      break
    case 'modifyContents':
      drawer = (
        <DynamicModifyContentsOverlay
          subscription={state.subscription}
          availableProducts={availableProducts}
          currentSubscriptionProducts={state.currentSubscriptionProducts}
          title={state.accountSettings?.subscriptionEditingFlyout.title}
          description={
            state.accountSettings?.subscriptionEditingFlyout.description
          }
          buttonText={
            state.accountSettings?.subscriptionEditingFlyout.buttonText
          }
        />
      )
      break
    default:
      drawer = undefined
      break
  }

  return (
    <BabDrawerContext.Provider
      value={{
        openBabPurchaseDrawer,
        openBabEditDrawer,
        closeBabDrawer,
        availableProducts,
        openModifyContentsOverlay,
      }}
    >
      {isOpen && drawer}
      {children}
    </BabDrawerContext.Provider>
  )
}
