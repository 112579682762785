import Link from 'next/link'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useCustomerContext } from '@/context/CustomerContext'
import { useHeaderContext } from '@/context/HeaderContext'
import { useSearchModalContext } from '@/context/SearchModalContext'
import { useTheCatchContext } from '@/context/TheCatchContext'
import { useRouter } from 'next/router'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import { useMarketingContext } from '@/context/MarketingContext'
import { generateCatchUrl } from '@/utils/generateCatchUrl'
import classes from '../SecondaryNavigation.module.scss'

const SecondaryNavigation = ({ props }) => {
  const router = useRouter()
  const { closedDoors, setClosedDoors } = useHeaderContext()

  const { activeExperiments } = useMarketingContext()
  const isMemberPortal = router.pathname.startsWith('/account/')

  // AB TEST POC FOR SANITY SETTINGS
  // NOTE: the nav menu set in the experiment is ignored
  // the ab test menu navs are pulled from the header -- needs refactor
  let experimentMatch = false
  experimentMatch = activeExperiments.some(
    (exp) =>
      exp.trigger?.includes(props.nonMemberPrimaryNavigation.handle.current) &&
      exp.variant?.includes('serveVariant'),
  )

  const customerContext = useCustomerContext()
  const theCatchContext = useTheCatchContext()
  const { customer } = customerContext
  const { setMobileMenuIsOpen } = useHeaderContext()
  let menuItems;

  if (isMemberPortal) {
    menuItems = props?.memberPortalPrimaryNavigation?.menuItems;
  }

  menuItems = menuItems || []
  console.log('menuItems', menuItems)
  const searchModalContext = useSearchModalContext()
  const { setSearchOpen } = searchModalContext
  const { monthName, year } = theCatchContext

  let theCatchUrl = generateCatchUrl(customer, monthName, year)

  const renderMenuItems = (menuItems, theCatchUrl, classes) => {
    return menuItems
      .filter((item) => item?.linkAttributes?.isDesktop)
      .map((item) => {
        if (item.linkText === 'The Catch') {
          return (
            <li className={classes.navItem} key={item._key}>
              <Link href={theCatchUrl || '/'}>{item.linkText}</Link>
            </li>
          );
        } else {
          return (
            <li className={classes.navItem} key={item._key}>
              <Link href={item.linkUrl ? item.linkUrl : '/'}>
                {item.linkText}
              </Link>
            </li>
          );
        }
      });
  };

    return (
      <ResponsiveComponent
        component="ul"
        display="flex"
        className={classes.navItems}
        desktopBreakpoint={'sm'}
      desktop={
        !closedDoors && renderMenuItems(menuItems, theCatchUrl, classes)
      }
      mobile={
        !closedDoors && renderMenuItems(menuItems, theCatchUrl, classes)
      }
    />
  )
}

SecondaryNavigation.propTypes = {
  props: PropTypes.shape({
    memberPortalPrimaryNavigation: PropTypes.object,
    memberPrimaryNavigation: PropTypes.object,
    nonMemberPrimaryNavigationBTestVariant: PropTypes.object,
    nonMemberPrimaryNavigation: PropTypes.object
  }).isRequired,
  classes: PropTypes.object.isRequired
}

export default SecondaryNavigation
