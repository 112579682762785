export const generateCatchUrl = (customer, monthName, year) => {
    const basePath = '/the-catch';
    const defaultPath = `${basePath}/premium-seafood-box-${monthName}-${year}`;
  
    if (!customer?.tags || !Array.isArray(customer.tags)) {
      return defaultPath;
    }
  
    const { tags } = customer;
    
    const pathMap = [
      { tag: 'PS', path: defaultPath },
      { tag: 'PSWS', path: `${basePath}/premium-seafood-box-no-shellfish-${monthName}-${year}` },
      { tag: 'SF', path: `${basePath}/seafood-box-${monthName}-${year}` },
      { tag: 'SF-BI', path: `${basePath}/seafood-box-${monthName}-${year}` },
      { tag: 'S', path: `${basePath}/salmon-box-${monthName}-${year}` },
      { tag: 'SALP', path: `${basePath}/premium-salmon-box-${monthName}-${year}` },
    ];
  
    for (const { tag, path } of pathMap) {
      if (tags?.includes(tag)) {
        return path;
      }
    }
    
    return defaultPath;
  };